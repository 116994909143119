import { Address, Firm } from '@eo-storefronts/eo-core'
import { FormikProps, useFormik } from 'formik'
import AccountAddressFields from '~/src/components/profile/account/address/AccountAddressFields'
import { Box } from '@mui/material'
import AccountAddressActions from '~/src/components/profile/account/address/AccountAddressActions'
import * as yup from 'yup'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import FormTitle from '~/src/components/utils/form/FormTitle'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { AddressFormInterface } from '~/src/types/forms/AddressFormInterface'
import useAddressForm from '~/src/hooks/customer/useAddressForm'

interface Props {
  address?: Address,
}

const AccountAddressForm = ({ address }: Props) => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()
  const { submit } = useAddressForm()
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const formik: FormikProps<AddressFormInterface> = useFormik<AddressFormInterface>({
    initialValues: {
      id: address?.id ?? 0,
      name: address?.description ?? '',
      isDefault: address?.isDefault ?? false,
      street: address?.street ?? '',
      houseNumber: address?.houseNumber ?? '',
      bus: address?.bus ?? '',
      locality: address?.locality ?? '',
      zipCode: address?.zipCode ?? '',
      country: address?.country ? JSON.stringify(address.country) : JSON.stringify(firm?.address.country)
    },
    validationSchema: yup.object({
      name: yup.string().required(t('errors.addressNameRequired')),
      isDefault: yup.boolean().required(),
      street: yup.string().required(t('errors.streetNameRequired')),
      // houseNumber: yup.string().required(t('errors.houseNumberRequired')),
      bus: yup.string(),
      locality: yup.string().required(t('errors.localityRequired')),
      zipCode: yup.string().required(t('errors.zipcodeRequired')),
      country: yup.string().required(t('errors.countryRequired'))
    }),
    onSubmit: async ({
      id,
      name,
      isDefault,
      street,
      houseNumber,
      bus,
      locality,
      zipCode,
      country
    }: AddressFormInterface) => {
      await submit({
        id,
        description: name,
        isDefault: isDefault,
        street,
        houseNumber: houseNumber,
        bus,
        locality,
        zipCode: zipCode,
        country: JSON.parse(country)
      } as Address,
      {
        redirectAfterSubmit: true,
        redirectUrl: `${RoutesEnum.PROFILE}/${RoutesEnum.ADDRESSES}`
      }
      )
    }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        [theme.breakpoints.down('sm')]: {
          gap: 2
        }
      }}
    >
      <FormTitle
        title={t(address?.id ? 'profile.address.editAddress' : 'profile.address.newAddress')}
        subTitle={t(address?.id ? 'profile.address.editYourAddress' : 'profile.address.addANewAddress')}
      />
      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'grid',
          gridTemplateAreas: '"name name-field . ."' +
            '"address street-field houseNumber-field ."' +
            '"city locality-field zipCode-field ."' +
            '"country country-field . ."' +
            '"actions actions actions actions"',
          gridTemplateColumns: '1.5fr 1fr 1fr 0.5fr',
          rowGap: 5,
          columnGap: 4,
          [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '1.5fr 1fr 1fr'
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateAreas: '"name name"' +
              '"name-field ."' +
              '"address address"' +
              '"street-field houseNumber-field"' +
              '"city city"' +
              '"locality-field zipCode-field"' +
              '"country country"' +
              '"country-field ."' +
              '"actions actions"',
            gridTemplateColumns: '1fr 1fr'
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: '"name"' +
              '"name-field"' +
              '"address"' +
              '"street-field"' +
              '"houseNumber-field"' +
              '"city"' +
              '"locality-field"' +
              '"zipCode-field"' +
              '"country"' +
              '"country-field"' +
              '"actions"',
            gridTemplateColumns: '1fr',
            rowGap: 2
          }
        }}
      >
        <AccountAddressFields form={formik} />
        <AccountAddressActions form={formik} />
      </Box>
    </Box>
  )
}

export default AccountAddressForm
